<template>
  <section class="has-padding-200">
    <hero />
    <about />
    <assortment />
    <google-map />
    <contact />
    <foot />
  </section>
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent';
import ErrorComponent from '@/components/ErrorComponent';

export default {
  name: 'Home',
  components: {
    hero: () => ({
      component: import('../components/Hero'),
      loading: LoadingComponent,
      error: ErrorComponent,
      timeout: 5000,
    }),
    about: () => ({
      component: import('../components/About'),
      loading: LoadingComponent,
      error: ErrorComponent,
      timeout: 5000,
      delay: 500,
    }),
    assortment: () => ({
      component: import('../components/Assortment'),
      loading: LoadingComponent,
      error: ErrorComponent,
      timeout: 5000,
      delay: 500,
    }),
    googleMap: () => ({
      component: import('../components/Map'),
      loading: LoadingComponent,
      error: ErrorComponent,
      timeout: 5000,
      delay: 500,
    }),
    foot: () => ({
      component: import('../components/Footer'),
      loading: LoadingComponent,
      error: ErrorComponent,
      timeout: 5000,
      delay: 500,
    }),
    contact: () => ({
      component: import('../components/ContactForm'),
      loading: LoadingComponent,
      error: ErrorComponent,
      timeout: 5000,
      delay: 500,
    }),
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .has-padding-200 {
    padding-top: 200px;
  }
}

@media only screen and (min-width: 769px) {
  .has-padding-200 {
    padding-top: 130px;
  }
}
</style>
